import apiClient from '@/services/axios'

const getICD10Code = async () => {
    const res = await apiClient.get('/api/icd-codes/icd10.json', {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getICD9CMCode = async () => {
    const res = await apiClient.get('/api/icd-codes/icd9cm.json', {})
    if (res) {
        return res
    } else {
        return null
    }
}

const searchICD10Code = async (search) => {
    const res = await apiClient.get(`/api/icd-codes/search/icd10?q=${search}&limit=50`)
    if (res) {
        return res
    } else {
        return null
    }
}

const searchICD9CMCode = async (search) => {
    const res = await apiClient.get(`/api/icd-codes/search/icd9cm?q=${search}&limit=50`)
    if (res) {
        return res
    } else {
        return null
    }
}

const generateICD10Code = async (data) => {
    const res = await apiClient.post('/api/icd-codes/generate/icd10', data)
    if (res) {
        return res
    } else {
        return null
    }
}

const generateICD9CMCode = async (data) => {
    const res = await apiClient.post('/api/icd-codes/generate/icd9cm', data)
    if (res) {
        return res
    } else {
        return null
    }
}

export {
    getICD10Code,
    getICD9CMCode,
    searchICD10Code,
    searchICD9CMCode,
    generateICD10Code,
    generateICD9CMCode,
}