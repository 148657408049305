<template>
    <div>
        <div class="floating-loading" v-if="getDataLoading">
            <div class="d-flex" style="border: 1px solid #E8E9EB; width: 120px; padding: 10px; border-radius: 10px;">
                <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 20px" class="mr-3 mb-0" spin />
                </a-spin>
                Loading...
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="mb-4 h4 font-weight-bold text-color-green">Pemeriksaan Dokter (Rawat Jalan)</div>
                <div class="card">
                    <div class="card-body background-blue">
                        <div class="">
                            <div class="row">
                                <div class="col-xl-4">
                                    <table>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Nama</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.Patient.name }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Tanggal Lahir</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.birth_date }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Usia</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.age }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-xl-4">
                                    <table>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Jenis Kelamin</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.gender }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Cara Pembayaran</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.Patient.payment_method }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Dokter</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.Doctor.User.name }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-xl-4">
                                    <table>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">No. RM</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.Patient.medical_record_no }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 120px; height:32px; padding-right: 8px;">Status</td>
                                            <td>:</td>
                                            <td style="padding-left: 8px">{{ this.patientVisitData.status }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row">
                        <div :class="[this.openDocument ? 'col-xl-6' : 'col-xl-4']">
                            <div class="d-flex">
                                <div style="width: 100%">
                                    <div class="d-flex align-items-center flex-wrap justify-content-between">
                                        <button :class="{'active': activeButton === 1}" @click="setActiveButton(1)">Identitas Pasien</button>
                                        <button :class="{'active': activeButton === 2}" @click="setActiveButton(2)">Anamnesis</button>
                                        <button :class="{'active': activeButton === 3}" @click="setActiveButton(3)">Penunjang</button>
                                        <button :class="{'active': activeButton === 4}" @click="setActiveButton(4)">CPPT</button>
                                    </div>
        
                                    <div v-if="activeButton === 1" class="content-div">
                                        <table>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Nama Lengkap</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.name }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Jenis Kelamin</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.gender }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Tempat Lahir</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.birth_place }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Tanggal Lahir</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.birth_date }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Usia</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.age }}</td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">No. Rekam Medis</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.medical_record_no }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">NIK</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.NIK }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Cara Pembayaran</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.payment_method }}</td>
                                            </tr>
                                            <tr v-if="this.patientVisitData.Patient.payment_method === 'Asuransi'">
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Nama Asuransi</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.insurance_name }}</td>
                                            </tr>
                                            <tr v-if="this.patientVisitData.Patient.payment_method !== 'Mandiri'">
                                                <td style="width: 130px; height:32px; padding-right: 15px;">No. Asuransi</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.payment_method === 'JKN'">{{ this.patientVisitData.Patient.bpjs_no }}</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.payment_method === 'Asuransi'">{{ this.patientVisitData.Patient.insurance_no }}</td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">No. Telepon Rumah</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.telephone_no">{{ this.patientVisitData.Patient.telephone_no }}</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.telephone_no === null">-</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">No. Telepon Selular</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.phone_no }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Email</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.email">{{ this.patientVisitData.Patient.email }}</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.email === null">-</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;" v-if="this.patientVisitData.Patient.identity_nation === 'Indonesia'">Alamat KTP</td>
                                                <td style="width: 130px; height:32px; padding-right: 15px;" v-else>Alamat Identitas</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.identity_nation === 'Indonesia'">{{ this.patientVisitData.Patient.identity_address }}, RT {{ this.patientVisitData.Patient.identity_RT }}, RW {{ this.patientVisitData.Patient.identity_RW }}, Kelurahan {{ this.patientVisitData.Patient.identity_village }}, Kecamatan {{ this.patientVisitData.Patient.identity_district }}, {{ this.patientVisitData.Patient.identity_regency }}, {{ this.patientVisitData.Patient.identity_province }}, {{ this.patientVisitData.Patient.identity_nation }}, {{ this.patientVisitData.Patient.identity_postal_code }}</td>
                                                <td style="padding-left: 15px" v-else>{{ this.patientVisitData.Patient.identity_address }}, {{ this.patientVisitData.Patient.identity_nation }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Alamat Domisili</td>
                                                <td style="padding-left: 15px" v-if="this.patientVisitData.Patient.domicile_nation === 'Indonesia'">{{ this.patientVisitData.Patient.domicile_address }}, RT {{ this.patientVisitData.Patient.domicile_RT }}, RW {{ this.patientVisitData.Patient.domicile_RW }}, Kelurahan {{ this.patientVisitData.Patient.domicile_village }}, Kecamatan {{ this.patientVisitData.Patient.domicile_district }}, {{ this.patientVisitData.Patient.domicile_regency }}, {{ this.patientVisitData.Patient.domicile_province }}, {{ this.patientVisitData.Patient.domicile_nation }}, {{ this.patientVisitData.Patient.domicile_postal_code }}</td>
                                                <td style="padding-left: 15px" v-else>{{ this.patientVisitData.Patient.domicile_address }}, {{ this.patientVisitData.Patient.domicile_nation }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Nama Ibu Kandung</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.biological_mother_name }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Agama</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.religion }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Suku</td>
                                                <td v-if="this.patientVisitData.Patient.ethnic" style="padding-left: 15px">{{ this.patientVisitData.Patient.ethnic }}</td>
                                                <td v-else style="padding-left: 15px">-</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Bahasa</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.language }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Pendidikan</td>
                                                <td v-if="this.patientVisitData.Patient.education" style="padding-left: 15px">{{ this.patientVisitData.Patient.education }}</td>
                                                <td v-else style="padding-left: 15px">-</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Pekerjaan</td>
                                                <td v-if="this.patientVisitData.Patient.job" style="padding-left: 15px">{{ this.patientVisitData.Patient.job }}</td>
                                                <td v-else style="padding-left: 15px">-</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 130px; height:32px; padding-right: 15px;">Status Pernikahan</td>
                                                <td style="padding-left: 15px">{{ this.patientVisitData.Patient.martial_status }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-if="activeButton === 2" class="content-div">
                                        <table>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Waktu</td>
                                                <td style="padding-left: 15px;" colspan="2">{{ this.patientVisitData.anamnesis_created_time }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Nama Perawat</td>
                                                <td style="padding-left: 15px;" colspan="2">{{ this.patientVisitData.anamnesis_created_by }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Keluhan Utama</td>
                                                <td style="padding-left: 15px;" colspan="2">
                                                    <span v-for="(keluhan, index) in this.patientVisitData.Anamnesis.ChiefComplaints" :key="index">
                                                        <span v-if="patientVisitData.Anamnesis.ChiefComplaints.length === 1">
                                                            {{ keluhan.keterangan }}
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="index < patientVisitData.Anamnesis.ChiefComplaints.length - 1">
                                                                {{ keluhan.keterangan }},
                                                            </span>
                                                            <span v-else>
                                                                {{ keluhan.keterangan }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Riwayat Penyakit</td>
                                                <td style="padding-left: 15px;" colspan="2">
                                                    <ul style="margin: 0; padding-left: 20px;">
                                                        <li v-for="(penyakit, index) in this.patientVisitData.Anamnesis.IllnessHistories" :key="index">
                                                            <div>{{ penyakit.type === 'personal'? 'Riwayat Penyakit Pribadi' : 'Riwayat Penyakit Keluarga' }} | {{ penyakit.display }}</div>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Riwayat Alergi</td>
                                                <td v-if="this.patientVisitData.Anamnesis.Allergies.length === 0" style="padding-left: 15px;" colspan="2">Tidak Ada</td>
                                                <td v-else style="padding-left: 15px;" colspan="2">
                                                    <ul style="margin: 0; padding-left: 20px;">
                                                        <li v-for="(allergy, index) in this.patientVisitData.Anamnesis.Allergies" :key="index">
                                                            <div>{{ getAllergyType(allergy.type) }} | {{ allergy.display }}</div>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Riwayat Pengobatan</td>
                                                <td style="padding-left: 15px;" colspan="2">
                                                    <ul style="margin: 0; padding-left: 20px;">
                                                        <li v-for="(medication, index) in this.patientVisitData.Anamnesis.MedicationStatements" :key="index">
                                                            <div>{{ medication.name }} | {{ medicineStatus.find(item => item.value === medication.status).label }}</div>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Suhu</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled
                                                    v-model="this.patientVisitData.Anamnesis.suhu"
                                                    style="width: 100%;"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">°C</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Tekanan Darah Sistolik</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.tekanan_darah_sistolik"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">mmHg</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Tekanan Darah Diastolik</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.tekanan_darah_diastolik"
        
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">mmHg</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Saturasi Oksigen</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.saturasi_oksigen"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">%</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Frekuensi Nafas</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.frekuensi_nafas"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">kali/menit</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Nadi</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.nadi"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">kali/menit</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Tinggi Badan</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled
                                                    v-model="this.patientVisitData.Anamnesis.tinggi_badan"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">cm</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; height:40px; padding-right: 15px;">Berat Badan</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.berat_badan"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">kg</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 180px; padding-right: 15px;">Lingkar Perut</td>
                                                <td style="padding-left: 15px; width: 130px;">
                                                    <a-input-number
                                                    disabled 
                                                    v-model="this.patientVisitData.Anamnesis.lingkar_perut"
                                                    style="width: 100%"
                                                    />
                                                </td>
                                                <td style="padding-left: 12px;">cm</td>
                                            </tr>
                                            
                                        </table>
                                    </div>
                                    <div v-if="activeButton === 3" class="content-div">
                                        <div class="h6" v-if="this.patientVisitDocument.length === 0">Pasien tidak memiliki dokumen penunjang.</div>
                                        <ul class="file-list">
                                            <li v-for="(file,index) in this.patientVisitDocument" :key="index">
                                                <div class="font-weight-bold">{{ formatDateTimeFile(file.createdAt) }}</div>
                                                <div>Diunggah oleh {{ file.created_by_name }}</div>
                                                <div class="d-flex align-items-center list-border">
                                                    <div>
                                                        <img v-if="file.media_mime_type=== 'application/pdf'" src="resources/images/pdf-icon.svg" alt="Document" />
                                                        <img v-else :src="photoURL[index]" style="height: 48px; width: 48px;" />
                                                    </div>
                                                    <!-- <a :href="photoURL[index]" target="_blank" class="ml-2">{{ file.name }}</a> -->
                                                    <a @click="openFile(file,index)" target="_blank" class="ml-2">{{ file.name }}</a>
                                                </div>
                                                <a-divider/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-if="activeButton === 4">
                                        <div class="h6 content-div" v-if="this.patientVisitHistory.length === 0">Pasien tidak memiliki riwayat pemeriksaan.</div>
                                        <a-collapse accordion class="content-div-no-border">
                                            <a-collapse-panel :header="formatDateTimeFile(item.visit.createdAt)" :key="index" v-for="(item, index) in this.patientVisitHistory" >
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Nama RS/Klinik</td>
                                                            <td style="padding-left: 8px">RS Lorem Ipsum</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Nama Dokter</td>
                                                            <td style="padding-left: 8px">{{ item.visit.doctor_name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Nama Perawat</td>
                                                            <td style="padding-left: 8px">{{ item.visit.nurse_name }}</td>
                                                        </tr>
                                                    </table>
                                                    <a-divider/>
                                                    <table>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Keluhan Utama</td>
                                                            <td style="padding-left: 8px">
                                                                <span v-for="(keluhan, index) in item.visit.Anamnesis.ChiefComplaints" :key="index">
                                                                    <span v-if="item.visit.Anamnesis.ChiefComplaints.length === 1">
                                                                        {{ keluhan.keterangan }}
                                                                    </span>
                                                                    <span v-else>
                                                                        <span v-if="index < item.visit.Anamnesis.ChiefComplaints.length - 1">
                                                                            {{ keluhan.keterangan }},
                                                                        </span>
                                                                        <span v-else>
                                                                            {{ keluhan.keterangan }}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <br>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Riwayat Penyakit</td>
                                                            <td style="padding-left: 8px">
                                                                <ul style="margin: 0; padding-left: 20px;">
                                                                    <li v-for="(penyakit, index) in item.visit.Anamnesis.IllnessHistories" :key="index">
                                                                        <div>{{ penyakit.type === 'personal'? 'Riwayat Penyakit Pribadi' : 'Riwayat Penyakit Keluarga' }} | {{ penyakit.display }}</div>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <br>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Riwayat Alergi</td>
                                                            <td style="padding-left: 8px" v-if="item.visit.Anamnesis.Allergies.length === 0">Tidak Ada</td>
                                                            <td style="padding-left: 8px" v-else>
                                                                <ul style="margin: 0; padding-left: 20px;">
                                                                    <li v-for="(allergy, index) in item.visit.Anamnesis.Allergies" :key="index">
                                                                        <div>{{ getAllergyType(allergy.type) }} | {{ allergy.display }}</div>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <br>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Riwayat Pengobatan</td>
                                                            <td style="padding-left: 8px">
                                                                <ul style="margin: 0; padding-left: 20px;">
                                                                    <li v-for="(medication, index) in item.visit.Anamnesis.MedicationStatements" :key="index">
                                                                        <div>{{ medication.name }} | {{ medicineStatus.find(item => item.value === medication.status).label }}</div>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <br>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Suhu</td>
                                                            <td style="padding-left: 8px">{{ item.visit.Anamnesis.suhu }} °C</td>

                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Tekanan Darah Sistolik</td>
                                                            <td style="padding-left: 8px">{{ item.visit.Anamnesis.tekanan_darah_sistolik }} mmHg</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Tekanan Darah Diastolik</td>
                                                            <td style="padding-left: 8px">{{ item.visit.Anamnesis.tekanan_darah_diastolik }} mmHg</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Saturasi Oksigen</td>
                                                            <td style="padding-left: 8px">{{ item.visit.Anamnesis.saturasi_oksigen }} %</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Nadi</td>
                                                            <td style="padding-left: 8px">{{ item.visit.Anamnesis.nadi }} kali/menit</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Frekuansi Nafas</td>
                                                            <td style="padding-left: 8px">{{ item.visit.Anamnesis.frekuensi_nafas }} kali/menit</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Tinggi Badan</td>
                                                            <td style="padding-left: 8px" v-if="item.visit.Anamnesis.tinggi_badan">{{ item.visit.Anamnesis.tinggi_badan }} cm</td>
                                                            <td style="padding-left: 8px" v-else>-</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Berat Badan</td>
                                                            <td style="padding-left: 8px" v-if="item.visit.Anamnesis.berat_badan">{{ item.visit.Anamnesis.berat_badan }} kg</td>
                                                            <td style="padding-left: 8px" v-else>-</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 165px; height:32px; padding-right: 8px;">Lingkar Perut</td>
                                                            <td style="padding-left: 8px" v-if="item.visit.Anamnesis.lingkar_perut">{{ item.visit.Anamnesis.lingkar_perut }} cm</td>
                                                            <td style="padding-left: 8px" v-else>-</td>
                                                        </tr>
                                                    </table>
                                                    <a-divider/>
                                                    <div>
                                                        <p class="h6 font-weight-bold">Subjective</p>
                                                        <div v-html="formatText(item.visit.DoctorNote.subjective)"></div>
                                                        <br>
                                                        <p class="h6 font-weight-bold">Objective</p>
                                                        <div v-html="formatText(item.visit.DoctorNote.objective)"></div>
                                                        <br>
                                                        <p class="h6 font-weight-bold">Assessment</p>
                                                        <div v-html="formatText(item.visit.DoctorNote.assessment)"></div>
                                                        <div>Kode ICD-10:</div>
                                                        <div>Primer:</div>
                                                        <div>• {{ item.visit.DoctorNote.Conditions[0].code }} | {{ item.visit.DoctorNote.Conditions[0].STR }}</div>
                                                        <div v-if="item.visit.DoctorNote.Conditions.length > 1">
                                                            <div>Sekunder:</div>
                                                            <div v-for="(condition, index) in item.visit.DoctorNote.Conditions.slice(1)" :key="index">• {{ condition.code }} | {{ condition.STR }}</div>
                                                        </div>
                                                        <br>
                                                        <p class="h6 font-weight-bold">Plan</p>
                                                        <div v-html="formatText(item.visit.DoctorNote.plan)"></div>
                                                        <div>Kode ICD-9-CM:</div>
                                                        <div v-for="(procedure, index) in item.visit.DoctorNote.Procedures" :key="index">• {{ procedure.code }} | {{ procedure.STR }}</div>
                                                        <div v-if="item.visit.DoctorNote.Procedures.length === 0">-</div>
                                                        <br>
                                                    </div>
                                                    <div>
                                                        <p class="h6 font-weight-bold">Resep</p>
                                                        <div v-if="item.visit.DoctorNote.MedicationRequests.length === 0">Tidak ada</div>
                                                        <div v-else>
                                                            <ul>
                                                                <li v-for="(medication, index) in item.visit.DoctorNote.MedicationRequests" :key="index">
                                                                    <div>{{ medication.name }} | {{ medication.quantity }} ({{ medication.dosage }} x {{ medication.frequency }}, {{ medication.use_morning === true? 'Pagi' : '' }} {{ medication.use_noon === true? 'Siang' : '' }} {{ medication.use_night === true? 'Malam' : '' }}) </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <p class="h6 font-weight-bold">Catatan Resep</p>
                                                        <div v-if="item.visit.DoctorNote.medication_note === null">Tidak ada</div>
                                                        <div v-else v-html="formatText(item.visit.DoctorNote.medication_note)"></div>
                                                        <br>
                                                    </div>
                                                    <div>
                                                        <p class="h6 font-weight-bold">Foto/Dokumen Penunjang</p>
                                                        <p v-if="item.documents.length === 0">Tidak ada</p>
                                                        <div v-else>
                                                            <ul class="file-list">
                                                                <li v-for="(file,index) in item.documents" :key="index">
                                                                    <div class="font-weight-bold">{{ formatDateTimeFile(file.VisitDocument.createdAt) }}</div>
                                                                    <div>Diunggah oleh {{ file.created_by_name }}</div>
                                                                    <div class="d-flex align-items-center list-border">
                                                                        <div>
                                                                            <img v-if="file.media_mime_type=== 'application/pdf'" src="resources/images/pdf-icon.svg" alt="Document" />
                                                                            <img v-else :src="file.uri" style="height: 48px; width: 48px;" />
                                                                        </div>
                                                                        <a :href="file.uri" target="_blank" class="ml-2">{{ file.name }}</a>
                                                                    </div>
                                                                    <!-- <a-divider/> -->
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a-collapse-panel>
                                        </a-collapse>
                                    </div>
                                    <div v-if="activeButton === 5" class="content-div">
                                        <div class="d-flex align-items-center justify-content-start">
                                            <a @click="setActiveButton(3)">
                                                <img src="resources/images/left-arrow-icon.svg" alt="Icon" style="width: 8px; height: 12px; margin-right: 8px;">
                                            </a>
                                            <p style="margin:0">{{ formatDateTimeFile(this.selectedDocument.file.createdAt) }} | Diunggah Oleh {{ this.selectedDocument.file.created_by_name }}</p>
                                        </div>
                                        <br>
                                        <div v-if="this.selectedDocument.file.media_mime_type !== 'application/pdf'">
                                            <!-- <img :src="this.photoURL[this.selectedDocument.index]" style="width: 100%; height: 100%;" /> -->
                                            <object :data="this.photoURL[this.selectedDocument.index]" :type="this.selectedDocument.file.media_mime_type" width="100%" min-height="100%"></object>
                                        </div>
                                        <div v-else style="min-width:50vw">
                                            <!-- <iframe :src="this.photoURL[this.selectedDocument.index]" width="100%" height="600px"></iframe> -->
                                            <object :data="this.photoURL[this.selectedDocument.index]" :type="this.selectedDocument.file.media_mime_type" height="600px" style="min-width: 80%"></object>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-1">
                                    <a-divider type="vertical" style="min-height: 100%"/>
                                </div>
                            </div>
                            
                        </div>

                        <div :class="[this.openDocument ? 'col-xl-6' : 'col-xl-8']">
                            <p class="h5 font-weight-bold">Auto-Transkripsi Sesi Pemeriksaan</p>
                            <p class="h6">Rekaman sesi asesmen akan ditranskripsikan dan diolah menjadi catatan SOAP secara otomatis. Dokter perlu menyebutkan objective, assessment, dan plan dengan lengkap agar data tersebut masuk ke transkrip dan dapat diolah menjadi catatan SOAP yang akurat.</p>
                            <br>
                            <div v-if="this.isStartRecording">
                                <p class="h6 font-weight-bold">Transkrip</p>
                                <p class="h6">Transkrip dapat diubah dengan menekan kotak area dan mengetik perubahan secara langsung</p>
                                <a-textarea
                                    v-model="transcript"
                                    :rows="10"
                                    style="width: 100%"
                                    :disabled="generateSOAPLoading"
                                />
                                <div class="buttons-container">
                                    <a-button shape="round" size="large" v-if="this.isRecording" class="button stop-button" @click.prevent="stopRecording">
                                        <img src="resources/images/stop-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                        Berhenti Merekam
                                    </a-button>
                                    <div v-else class="buttons-container mt-3">
                                        <a-button shape="round" size="large" @click="startRecording" :disabled="generateSOAPLoading">
                                            <img src="resources/images/mic-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                            Lanjut Merekam
                                        </a-button>
                                        <!-- <a-button shape="round" type="primary" size="large" class="button"> -->
                                        <a-button shape="round" class="ml-2" type="primary" size="large"  :loading="generateSOAPLoading" @click="generateSOAPFromTranscript">
                                            <img src="resources/images/note-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">    
                                            Buat SOAP
                                        </a-button>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <a-button  v-if="!this.isRecording" shape="round" size="large" type="primary" class="button left-button" @click.prevent="openRecordingForm" :disabled="getDataLoading">
                                    <img src="resources/images/mic-white-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                    Mulai Rekam
                                </a-button>
                            </div>

                            <a-divider/>

                            <div class="loading-container" v-if="generateSOAPLoading === true">
                                <div class="loading-content">
                                    <a-spin :indicator="indicator" class="mb-3" />
                                    <br>
                                    <p class="h5 font-weight-bold">Mohon Tunggu</p>
                                    <p class="h5">Transkrip sedang diubah menjadi catatan SOAP</p>
                                </div>
                            </div>

                            <div v-show="generateSOAPLoading === false">
                                <p class="h5 font-weight-bold">Catatan SOAP</p>
                                <br>
                                <a-form :form="form" @submit.prevent="handleSubmitSOAP">
                                    <div class="row">
                                        <div class=col-xl-6>
                                            <a-form-item>
                                                <p class="h6 font-weight-bold">Subjective<span class="text-danger">*</span></p>
                                                <a-textarea
                                                    v-decorator="['subjective',
                                                    {
                                                        rules: [{ required: true, message: 'Kolom ini wajib diisi' }],
                                                        initialValue: ''
                                                    },
                                                    ]"
                                                    :rows="8"
                                                    style="width: 100%"
                                                    placeholder="Tekan kotak area untuk mengetik secara manual atau buat secara otomatis melalui fitur Auto-Transkripsi"
                                                    :disabled="this.getDataLoading"
                                                />
                                            </a-form-item>
                                            <div class="d-flex justify-content-end mt-2">
                                                <!-- <a-button shape="round" size="large" class="button">
                                                    <img src="resources/images/mic-green-logo.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                    Dikte
                                                </a-button> -->
                                            </div>
                                        </div>
                                        <div class=col-xl-6>
                                            <a-form-item>
                                                <div class="d-flex align-items-center" style="height: 26px;">
                                                    <p class="h6 font-weight-bold mr-2">Objective<span class="text-danger">*</span></p>
                                                    <a-popover title="" placement="rightBottom">
                                                        <template slot="content" style="background-color: black">
                                                            <p style="max-width: 320px; margin: 0">Template pemeriksaan fisik dibuat secara otomatis oleh sistem berdasarkan spesialisasi dokter. Anda dapat mengubah atau menghapusnya sesuai kebutuhan.
                                                                Isi secara manual atau rekam sesi asesmen untuk mengisi objective secara otomatis.</p>
                                                        </template>
                                                        <a class="pb-2">
                                                            <img src="resources/images/info-icon.svg" alt="Icon" style="width: 20px; height: 20px;">     
                                                        </a>
                                                    </a-popover>
                                                </div>
                                                <a-textarea
                                                    v-decorator="['objective',
                                                    {
                                                        rules: [{ required: true, message: 'Kolom ini wajib diisi' }],
                                                        initialValue: ''
                                                    }
                                                    ]"
                                                    :rows="8"
                                                    style="width: 100%"
                                                    placeholder="Tekan kotak area untuk mengetik secara manual atau buat secara otomatis melalui fitur Auto-Transkripsi"
                                                    :disabled="this.getDataLoading"
                                                />
                                            </a-form-item>
                                            <div class="d-flex justify-content-end mt-2">
                                                <!-- <a-button shape="round" size="large" class="button">
                                                    <img src="resources/images/mic-green-logo.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                    Dikte
                                                </a-button> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class=col-xl-6>
                                            <a-form-item>
                                                <div class="d-flex align-items-center" style="height: 26px;">
                                                    <p class="h6 font-weight-bold mr-2">Assessment<span class="text-danger">*</span></p>
                                                    <a-popover title="" placement="right">
                                                        <template slot="content" style="background-color: black">
                                                            <p style="max-width: 320px; margin: 0">Assessment dari kunjungan pasien sebelumnya ditambahkan secara otomatis ke dalam kotak area. Anda dapat mengubah atau menghapus assessment sesuai kebutuhan.</p>
                                                        </template>
                                                        <a class="pb-2">
                                                            <img src="resources/images/info-icon.svg" alt="Icon" style="width: 20px; height: 20px;">     
                                                        </a>
                                                    </a-popover>
                                                </div>
                                                <a-textarea
                                                    v-decorator="['assessment',
                                                    {
                                                        rules: [{ required: true, message: 'Kolom ini wajib diisi' }],
                                                        initialValue: ''
                                                    }
                                                    ]"
                                                    :rows="8"
                                                    style="width: 100%"
                                                    placeholder="Tekan kotak area untuk mengetik secara manual atau buat secara otomatis melalui fitur Auto-Transkripsi"
                                                    :disabled="this.getDataLoading"
                                                />
                                            </a-form-item>
                                            <div class="d-flex justify-content-end mt-2">
                                                <a-button shape="round" size="large" class="button mr-3" :loading="ICD10Loading" @click="generateICD10">
                                                    <img src="resources/images/stars-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                    ICD-10 Otomatis
                                                </a-button>
                                                <!-- <a-button shape="round" size="large" class="button">
                                                    <img src="resources/images/mic-green-logo.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                    Dikte
                                                </a-button> -->
                                            </div>
                                            <div class="mb-2" v-if="this.assessmentRecommendation !== ''">
                                                <a class="d-flex flex-row align-items-center" @click="openrecommendationAssessmentModal">
                                                    <div class="font-weight-bold">Rekomendasi Assessment</div>
                                                    <img src="resources/images/left-arrow-icon.svg" alt="Icon" :style="arrowAssessmentStyle">
                                                </a>
                                                <div v-if="recommendationAssessmentModal === true">
                                                    <div>{{ this.assessmentRecommendation }}</div>
                                                    <a @click="copyAssessment" class="d-flex flex-row align-items-center mt-2 mb-2">
                                                        <img src="resources/images/copy-icon.svg" alt="Icon" style="width: 16px; height: 16px;">
                                                        <div style="color: #428A94; margin-left: 4px;">Masukkan Rekomendasi ke Assessment</div>
                                                    </a>
                                                </div>
                                            </div>
                                            <!-- rekomendasi conditions -->
                                             <div v-if="this.conditions_autoGenerated.length > 0">
                                                 <div class="font-weight-bold">Rekomendasi ICD-10</div>
                                                 <div>Tekan kode ICD-10 untuk menembahkan kode ke diagnosis</div>
                                                 <div class="recommendation-button-container">
                                                    <div v-for="(text, index) in conditions_autoGenerated" :key="index">
                                                        <a-button  class="mb-2" style="border: 1px solid #428A94" shape="round" @click="addConditions(text)">{{ text.code }}</a-button>
                                                    </div>
                                                 </div>
                                             </div>
                                            <!-- primary_conditions -->
                                             <div>
                                                <p class="font-weight-bold">Diagnosis Primer<span class="text-danger">*</span></p>
                                                <!-- <div v-for="(text, index) in primary_condition_autoGenerated" :key="index">
                                                    <div class="d-flex align-items-center" style="height: 2.5rem">
                                                        <div class="mr-3 d-flex row align-items-center justify-content-start" style="width:90%">
                                                            <div class="col-xl-9">{{ text.code }}</div>
                                                            <div class="col-xl-3">
                                                                <div class="tag">
                                                                    Rekomendasi
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between" style="width: 50px">
                                                            <a @click="removePrimaryAutoInput(index)" class="h4">x</a>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <a-form-item v-for="(input, index) in primary_condition" :key="index">
                                                    <div class="d-flex align-items-center" style="height: 2.5rem">
                                                        <div class="mr-3" style="width:90%">
                                                            <a-select 
                                                                show-search
                                                                @search="searchICD10"
                                                                :filter-option="false"
                                                                :not-found-content="null"
                                                                v-model="input.code" 
                                                                :default-active-first-option="false"
                                                                :show-arrow="false"
                                                                style="min-width: 15rem;">
                                                                <a-select-option v-for="item in ICD10List" :key="item.skri" :value="item.skri + ' | ' + item.STR" > {{ item.skri }} | {{ item.STR }}</a-select-option>
                                                            </a-select>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between" style="width: 50px">
                                                            <!-- <div>
                                                                <i class="fa fa-arrow-up mr-2" @click="moveUp(index)" v-if="index>0 && primary_conditions.length>0"></i>
                                                                <i class="fa fa-arrow-down mr" @click="moveDown(index)" v-if="index<primary_conditions.length-1 && primary_conditions.length>0"></i>
                                                            </div> -->
                                                            <a @click="removePrimaryInput(index)" class="h4">x</a>
                                                        </div>
                                                    </div>
                                                </a-form-item>
                                                <!-- <div @click="addPrimaryInput" v-if="primary_condition.length === 0 && primary_condition_autoGenerated.length === 0" class="mt-2 h6 mb-3" style="color: #428A94"><a>+ Tambah Kode ICD-10</a></div> -->
                                                <div @click="addPrimaryInput" v-if="primary_condition.length === 0" class="mt-2 h6 mb-3" style="color: #428A94"><a>+ Tambah Kode ICD-10</a></div>
                                             </div>
    
                                            <!-- secondary_conditions -->
                                            <div>
                                                <p class="font-weight-bold">Diagnosis Sekunder</p>
                                                <!-- <div v-for="(text, index) in secondary_conditions_autoGenerated" :key="index">
                                                    <div class="d-flex align-items-center" style="height: 2.5rem">
                                                        <div class="mr-3 d-flex row align-items-center justify-content-start" style="width:90%">
                                                            <div class="col-xl-9">{{ text.code }}</div>
                                                            <div class="col-xl-3">
                                                                <div class="tag">
                                                                    Rekomendasi
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between" style="width: 50px">
                                                            <a @click="removeSecondaryAutoInput(index)" class="h4">x</a>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <a-form-item v-for="(input, index) in secondary_conditions" :key="index">
                                                    <div class="d-flex align-items-center" style="height: 2.5rem">
                                                        <div class="mr-3" style="width:90%">
                                                            <a-select 
                                                                show-search
                                                                placeholder="Cari kode ICD-10"
                                                                @search="searchICD10"
                                                                :filter-option="false"
                                                                :not-found-content="null"
                                                                :show-arrow="false"
                                                                v-model="input.code" 
                                                                style="min-width: 15rem;">
                                                                <a-select-option v-for="item in ICD10List" :key="item.skri" :value="item.skri + ' | ' + item.STR" > {{ item.skri }} | {{ item.STR }}</a-select-option>
                                                            </a-select>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between" style="width: 50px">
                                                            <div>
                                                                <i class="fa fa-arrow-up mr-2" @click="moveUp(index)" v-if="index>0 && secondary_conditions.length>0"></i>
                                                                <i class="fa fa-arrow-down mr" @click="moveDown(index)" v-if="index<secondary_conditions.length-1 && secondary_conditions.length>0"></i>
                                                            </div>
                                                            <a @click="removeSecondaryInput(index)" class="h4">x</a>
                                                        </div>
                                                    </div>
                                                </a-form-item>
                                                <div v-if="secondary_conditions.length>1">Urutkan kode ICD dari diagnosa yang utama</div>
                                                <div @click="addSecondaryInput" class="mt-2 h6 mb-3" style="color: #428A94"><a>+ Tambah Kode ICD-10</a></div>
                                            </div>
                                        </div>
                                        <div class=col-xl-6>
                                            <a-form-item>
                                                <p class="h6 font-weight-bold">Plan<span class="text-danger">*</span></p>
                                                <a-textarea
                                                    v-decorator="['plan',
                                                    {
                                                        rules: [{ required: true, message: 'Kolom ini wajib diisi' }],
                                                        initialValue: ''
                                                    }
                                                    ]"
                                                    :rows="8"
                                                    style="width: 100%"
                                                    placeholder="Tekan kotak area untuk mengetik secara manual atau buat secara otomatis melalui fitur Auto-Transkripsi"
                                                    :disabled="this.getDataLoading"
                                                />
                                            </a-form-item>
                                            <div class="d-flex justify-content-end mt-2">
                                                <a-button shape="round" size="large" class="button mr-3" :loading="ICD9CMLoading" @click="generateICD9CM">
                                                    <img src="resources/images/stars-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                    ICD-9-CM Otomatis
                                                </a-button>
                                                <!-- <a-button shape="round" size="large" class="button">
                                                    <img src="resources/images/mic-green-logo.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                    Dikte
                                                </a-button> -->
                                            </div>
                                            <div class="mb-2" v-if="this.planRecommendation !== ''">
                                                <a class="d-flex flex-row align-items-center" @click="openrecommendationPlanModal">
                                                    <div class="font-weight-bold">Rekomendasi Plan</div>
                                                    <img src="resources/images/left-arrow-icon.svg" alt="Icon" :style="arrowPlanStyle">
                                                </a>
                                                <div v-if="recommendationPlanModal === true">
                                                    <div>{{ this.planRecommendation }}</div>
                                                    <a @click="copyPlan" class="d-flex flex-row align-items-center mt-2 mb-2">
                                                        <img src="resources/images/copy-icon.svg" alt="Icon" style="width: 16px; height: 16px;">
                                                        <div style="color: #428A94; margin-left: 4px;">Masukkan Rekomendasi ke Plan</div>
                                                    </a>
                                                </div>
                                            </div>
                                                <!-- <div v-for="(text, index) in procedures_autoGenerated" :key="index">
                                                    <div class="d-flex align-items-center" style="height: 2.5rem">
                                                        <div class="mr-3 d-flex flex-row align-items-center justify-content-start" style="width:90%">
                                                            <div class="col-xl-9">{{ text.code }}</div>
                                                            <div class="col-xl-3">
                                                                <div class="tag">
                                                                    Rekomendasi
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between" style="width: 50px">
                                                            <a @click="removeProceduresAutoInput(index)" class="h4">x</a>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            <a-form-item>
                                                <div v-if="this.procedures_autoGenerated > 0">
                                                    <div class="font-weight-bold">Rekomendasi ICD-9-CM</div>
                                                    <div>Tekan kode ICD-9-CM untuk menambahkan kode ke prosedur</div>
                                                    <div class="recommendation-button-container">
                                                        <div v-for="(text, index) in procedures_autoGenerated" :key="index">
                                                            <a-button  class="mb-2" style="border: 1px solid #428A94" shape="round" @click="addProcedures(text)">{{ text.code }}</a-button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="font-weight-bold">Tindakan</p>
                                                <a-form-item v-for="(input, index) in procedures" :key="index">
                                                    <div class="d-flex align-items-center" style="height: 2.5rem">
                                                        <div class="mr-3" style="width:90%">
                                                            <a-select 
                                                                show-search
                                                                @search="searchICD9CM"
                                                                :filter-option="false"
                                                                :not-found-content="null"
                                                                v-model="input.code" 
                                                                :default-active-first-option="false"
                                                                :show-arrow="false"
                                                                style="min-width: 15rem; "
                                                                >
                                                                <a-select-option v-for="procedure in ICD9CMList" :key="procedure.CODE" :value="procedure.CODE + ' | ' + procedure.STR">{{ procedure.CODE }} | {{ procedure.STR }}</a-select-option>
                                                            </a-select> 
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between" style="width: 50px">
                                                            <div>
                                                                <i class="fa fa-arrow-up mr-2" @click="moveProcedureUp(index)" v-if="index>0 && procedures.length>0"></i>
                                                                <i class="fa fa-arrow-down mr" @click="moveProcedureDown(index)" v-if="index<procedures.length-1 && procedures.length>0"></i>
                                                            </div>
                                                            <a @click="removeProceduresInput(index)" class="h4">x</a>
                                                        </div>
                                                    </div>
                                                </a-form-item>
                                                <div @click="addProceduresInput" class="mt-2 h6 mb-3" style="color: #428A94"><a>+ Tambah Kode ICD-9-CM</a></div>
                                            </a-form-item>
                                        </div>
                                    </div>
                                    <a-divider/>
                                    <div>
                                        <p class="h5 font-weight-bold">Resep dan Penunjang</p>
                                        <br>
                                        <div class="font-weight-bold mb-2">Resep<span class="text-danger">*</span></div>
                                        <a-form-item v-for="(input, index) in medications" :key="index">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <div>
                                                        <div class="font-weight-bold">Nama Obat</div>
                                                        <a-select 
                                                            show-search
                                                            @search="searchMedicine"
                                                            :filter-option="false"
                                                            :not-found-content="null"
                                                            v-model="input.name" 
                                                            :default-active-first-option="false"
                                                            :show-arrow="false"
                                                            style="min-width: 15rem; max-width: 70vw"
                                                            @change="selectMedicine(index, $event)"
                                                            >
                                                            <a-select-option v-for="medicine in medicineList" :key="medicine.id" :value="medicine.name">{{ medicine.name }}</a-select-option>
                                                        </a-select>
                                                    </div>
                                                    <div class="d-flex flex-container">
                                                        <div style="width: 150px; margin-right: 12px;">
                                                            <div class="font-weight-bold">Kuantitas</div>
                                                            <a-input v-model="input.quantity" placeholder="0 Tablet" style="width: 100%" />
                                                        </div>
                                                        <div style="width: 150px; margin-right: 12px;">
                                                            <div class="font-weight-bold">Frekuensi</div>
                                                            <a-select 
                                                                v-model="input.frequency"
                                                                style="width: 100%"
                                                                >
                                                                <a-select-option value="" disabled>Pilih Frekuensi</a-select-option>
                                                                <a-select-option v-for="frekuensi in frekuensiObat" :key="frekuensi" :value="frekuensi">{{ frekuensi }}</a-select-option>
                                                            </a-select>
                                                        </div>
                                                        <div style="width: 150px; margin-right: 12px;">
                                                            <div class="font-weight-bold">Dosis</div>
                                                            <a-input v-model="input.dosage" placeholder="0 Tablet" style="width: 100%" />
                                                        </div>
                                                        <div>
                                                            <div class="font-weight-bold">Waktu Penggunaan</div>
                                                            <div>
                                                                <a-checkbox v-model="input.use_morning" :defaultChecked="input.use_morning">Pagi</a-checkbox>
                                                                <a-checkbox v-model="input.use_noon" :defaultChecked="input.use_noon">Siang</a-checkbox>
                                                                <a-checkbox v-model="input.use_night" :defaultChecked="input.use_night">Malam</a-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a-divider/>
                                                </div>
                                                <div class="ml-3">
                                                    <a @click="removeMedication(index)" class="h2">x</a>
                                                </div>
                                            </div>
                                        </a-form-item>
                                        <div class="d-flex">
                                            <div style="color: #428A94" class="h6 mb-3 mr-4"><a @click="addMedication">+ Tambah Obat Baru</a></div>
                                            <div style="color: #428A94" class="h6 mb-3"><a @click="openPrevMedicationModal">+ Salin Obat dari Resep Lama</a></div>
                                        </div>
                                        <a-form-item>
                                            <div class="font-weight-bold mb-2">Catatan Resep</div>
                                            <a-textarea
                                                v-decorator="['medication_note',
                                                {
                                                    initialValue: ''
                                                }
                                                ]"
                                                :rows="3"
                                                style="width: 100%"
                                                placeholder="Tekan kotak area untuk menulis catatan resep apabila dibutuhkan"
                                            />
                                        </a-form-item>
                                    </div>
                                </a-form>
                            </div>

                            <div class="mt-2">
                                <p class="h6 font-weight-bold pb-2">Foto/Dokumen Penunjang Rekam Medis</p>
                                
                                <a-upload
                                v-model="fileList"
                                :showUploadList="false"
                                accept=".png,.jpg,.jpeg,.pdf"
                                :multiple="true"
                                :before-upload="beforeUpload"
                                >
                                
                                <button type="button" class="button right-button">
                                    <div class="fas fa-plus"></div>
                                     Tambah Foto/Dokumen
                                </button>
                                </a-upload>

                                <ul class="file-list">
                                    <li v-for="(file, index) in fileList" :key="index">
                                        <div class="d-flex align-items-center list-border">
                                            <img :src="file.type === 'application/pdf'? 'resources/images/pdf-icon.svg' :  file.thumbUrl" alt="thumbnail" class="mr-2" style="width: 48px; height: 48px;">
                                            <div v-if="editIndex === index">
                                            <input
                                                v-model="editName"
                                                @blur="finishEdit(index)"
                                                @keyup.enter="finishEdit(index)"
                                                type="text"
                                                autofocus
                                                class="edit-file-input"
                                            >
                                            <a @click="finishEdit(index)" class="ml-2">Simpan</a>
                                            </div>
                                            <div v-else class="d-flex justify-content-between" style="width:71rem">
                                                <div>
                                                    {{ file.name }}
                                                    <a @click="startEdit(index, file.name)">
                                                        <img
                                                        src="resources/images/edit-icon.svg"
                                                        alt="edit"
                                                        class="ml-2"
                                                        style="width: 16px; height: 16px;"
                                                        />
                                                    </a>
                                                </div>
                                                <a @click="removeFile(index)">
                                                    <img
                                                    src="resources/images/trash-icon.svg"
                                                    alt="delete"
                                                    style="width: 24px; height: 24px;"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <a-button v-if="fileList.length > 0" @click="uploadFiles" shape="round" size="large" >
                                    <img src="resources/images/cloud-upload-icon.svg" alt="Upload" style="width: 20px; height: 20px; margin-right: 8px;">
                                    Unggah Foto/Dokumen
                                </a-button>

                            </div>

                            <a-divider/>

                            <a-button shape="round" size="large" type="primary" class="button left-button" @click="openModal" :loading="submitLoading" :disabled="generateSOAPLoading">
                                <img src="resources/images/save-white-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                Simpan Hasil Pemeriksaan
                            </a-button>
                            <customModal :visible="showModal" @close="showModal = false" v-if="showModal === true">
                                <div class="mb-4 mt-2 h4 font-weight-bold text-color-green">Ringkasan Pemeriksaan Dokter</div>
                                <div class="card">
                                    <div class="card-body background-blue">
                                        <div class="">
                                            <div class="row">
                                                <div class="col-xl-4">
                                                    <table>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Nama</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.Patient.name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Tanggal Lahir</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.birth_date }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Usia</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.age }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-xl-4">
                                                    <table>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Jenis Kelamin</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.gender }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Cara Pembayaran</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.Patient.payment_method }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Dokter</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.Doctor.User.name }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-xl-4">
                                                    <table>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">No. RM</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.Patient.medical_record_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 120px; height:32px; padding-right: 8px;">Status</td>
                                                            <td>:</td>
                                                            <td style="padding-left: 8px">{{ this.patientVisitData.status }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-3 mr-3">
                                    <p class="h5 font-weight-bold">Transkrip</p>
                                    <div style="height:200px">
                                        <a-textarea
                                            v-model="transcript"
                                            :rows="10"
                                            style="width: 100%; height: 200px"
                                            read-only
                                        />
                                    </div>
                                    <div>
                                        <a-divider/>
                                        <p class="h5 font-weight-bold">Catatan SOAP</p>
                                        <!-- <br> -->
                                        <div class="font-weight-bold SOAP-title-color">Subjective</div>
                                        <div v-html="formatText(this.subjective)"></div>
                                        <br>
                                        <div class="font-weight-bold SOAP-title-color">Objective</div>
                                        <div v-html="formatText(this.objective)"></div>
                                        <br>
                                        <div class="font-weight-bold SOAP-title-color">Assessment</div>
                                        <div v-html="formatText(this.assessment)"></div>
                                        <div class="font-weight-bold">Diagnosis Primer:</div>
                                        <div v-if="this.primary_condition.length > 0">• {{ this.primary_condition[0].code }}</div>
                                        <div class="font-weight-bold">Diagnosis Sekunder:</div>
                                        <div v-if="this.secondary_conditions.length > 0">
                                            <div v-for="condition in this.secondary_conditions" :key="condition.code">
                                                • {{ condition.code }}
                                            </div>
                                        </div>
                                        <div v-if="this.secondary_conditions.length === 0">-</div>
                                        <br>
                                        <div class="font-weight-bold SOAP-title-color">Plan</div>
                                        <div v-html="formatText(this.plan)"></div>
                                        <div class="font-weight-bold">Kode Tindakan:</div>
                                        <div v-if="this.procedures.length>0">
                                            <div v-for="action in this.procedures" :key="action.code">
                                                • {{ action.code }}
                                            </div>
                                        </div>
                                        <div v-if="this.procedures.length === 0">-</div>
                                    </div>
                                    <div>
                                        <a-divider/>
                                        <p class="h5 font-weight-bold">Resep</p>
                                        <div class="font-weight-bold SOAP-title-color">Resep</div>
                                        <div v-if="this.medications.length > 0">
                                            <div v-for="medication in this.medications" :key="medication.name">
                                                • {{ medication.name }} | {{ medication.quantity }} ({{ medication.dosage }} x {{ medication.frequency }}, {{ medication.use_morning ? 'Pagi' : '' }} {{ medication.use_noon ? 'Siang' : '' }} {{ medication.use_night ? 'Malam' : '' }})
                                            </div>
                                        </div>
                                        <div v-if="this.medications.length === 0">-</div>
                                        <br>
                                        <div class="font-weight-bold SOAP-title-color">Catatan Resep</div>
                                        <div v-html="formatText(this.medication_note)"></div>
                                        <div v-if="this.medication_note === ''">Tidak Ada</div>
                                        <a-divider/>
                                    </div>
                                    <div>
                                        <a-checkbox @change="onCheckboxChange" :defaultChecked="this.verifiedCheckbox">
                                            Saya telah memverifikasi bahwa semua data medis pasien yang tercantum di atas adalah benar dan lengkap sesuai dengan hasil pemeriksaan yang telah dilakukan.
                                        </a-checkbox>
                                        <div class="mt-2">
                                            <a-button shape="round" size="large" type="primary" class="button left-button" @click="handleSubmitSOAP" :loading="submitLoading" :disabled="!this.verifiedCheckbox">
                                                <img src="resources/images/save-white-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                                                Simpan Hasil Pemeriksaan
                                            </a-button>
                                            <a @click="showModal = false" class="kit__utils__link font-size-16 ml-3">Batal</a>
                                        </div>
                                    </div>
                                </div>
                            </customModal>
                            <customModal :visible="showMedicationModal" @close="showMedicationModal = false" v-if="showMedicationModal === true" :size="'small'">
                                <div class="mb-4 mt-2 h4 font-weight-bold text-color-green">Salin Resep Lama</div>
                                <br>
                                <div class="h6" v-if="this.previousMedication.length === 0">Data resep lama tidak ditemukan.</div>
                                <a-collapse accordion class="content-div-no-border">
                                    <a-collapse-panel v-for="(item, index) in this.previousMedication" :key="index" :header="formatDateTimeFile(item.arrive_time)">
                                        <div v-for="(obat, index) in item.DoctorNote.MedicationRequests" :key="index">
                                            <div class="font-weight-bold">{{ obat.name }}</div>
                                            <div>Kuantitas: {{ obat.quantity }}</div>
                                            <div>Frekuensi: {{ obat.frequency }}</div>
                                            <div>Dosis: {{ obat.dosage }}</div>
                                            <div>Waktu Penggunaan: {{ obat.use_morning === true? 'Pagi': ''}} {{ obat.use_noon === true? 'Siang': ''}} {{ obat.use_night === true? 'Malam': ''}}</div>
                                            <br>
                                        </div>
                                        <a-button shape="round" size="large" type="primary" @click="copyMedication(item.DoctorNote.MedicationRequests)">
                                            <div class="d-flex">
                                                <div class="mr-2">+</div>
                                                Salin Resep Ini
                                            </div>
                                        </a-button>
                                    </a-collapse-panel>
                                </a-collapse>
                                <br>
                                <div style="color: #428A94" class="ml-2"><a @click="showMedicationModal = false">Batal</a></div>
                            </customModal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getPatientVisit, getDokumenPenunjang, uploadDokumenPenunjang, startDoctorCheckUp, saveDoctorNote, addAudio, generateSOAP } from '@/services/axios/api/kunjungan'
// import { getPatientVisit, getDokumenPenunjang, uploadDokumenPenunjang, startDoctorCheckUp, addAudio, generateSOAP } from '@/services/axios/api/kunjungan'

import { getById } from '@/services/axios/api/pasien'
import { getUserProfileFromId } from '@/services/axios/api/auth'
import { searchICD10Code, searchICD9CMCode, generateICD10Code, generateICD9CMCode } from '@/services/axios/api/icdCodes'
import { getMedications, getMedicationHistory } from '@/services/axios/api/pengobatan'
import customModal from './customModal.vue';
import DecibleVadConfig from './DecibleVadConfig';
import CircularArray from './CircularArray';

export default {
    components: {
        customModal,
    },
    computed: {
        arrowAssessmentStyle() {
            return  {
                width: '8px', height: '12px', marginLeft: '8px', transform: this.recommendationAssessmentModal === true ? 'rotate(90deg)' : 'rotate(270deg)',
            } 
        },
        arrowPlanStyle() {
            return  {
                width: '8px', height: '12px', marginLeft: '8px', transform: this.recommendationPlanModal === true ? 'rotate(90deg)' : 'rotate(270deg)',
            } 
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            activeButton: 1,
            patientId: '',
            visitId: '',
            patientVisitData: [],
            patientVisitDocument: [],
            patientVisitHistoryId: [],
            patientVisitHistory: [],
            anamnesisCreatedBy: '',
            isStartRecording: false,
            isRecording: false,
            photoURL: [],
            selectedDocument: {
                file: null,
                index: null,
            },
            ICD10List: [],
            ICD9CMList: [],
            medicineList: [],
            primary_condition: [],
            // primary_condition_autoGenerated: [],
            secondary_conditions: [],
            // secondary_conditions_autoGenerated: [],
            conditions_autoGenerated: [],
            procedures: [],
            procedures_autoGenerated: [],
            assessmentRecommendation: '',
            planRecommendation: '',
            medications: [],
            medication_note: '',
            frekuensiObat: ['1 Kali Per Hari', '2 Kali Per Hari', '3 Kali Per Hari', '4 Kali Per Hari', 'Tiap Jam', 'Tiap 2 Jam', 'Tiap 3 Jam', 'Tiap 4 Jam', 'Tiap 6 Jam', 'Tiap 8 Jam', 'Tiap 12 Jam', 'Tiap 24 Jam', '4 Hari Sekali', '1 Kali Per Minggu', 'Setiap 2 Minggu', 'Setiap 28 Hari', 'Setiap 30 Hari', 'Bila Perlu', '1 Kali', '1 Kali Seminggu', '2 Kali Seminggu', '3 Kali Seminggu', '4 Kali Seminggu'],
            fileList: [],
            editIndex: null,
            editName: '',
            transcript: '',
            recorder: null,
            mediaStream: null,
            audioSegments: [],
            audioChunks: [],
            ICD10Loading: false,
            ICD9CMLoading: false,
            submitLoading: false,
            generateSOAPLoading: false,
            indicator: <a-icon type="loading" style="font-size: 48px" spin />,
            openDocument : false,
            getDataLoading: false,
            recommendationAssessmentModal: false,
            recommendationPlanModal: false,
            showModal: false,
            showMedicationModal: false,
            subjective: '',
            objective: '',
            assessment: '',
            plan: '',
            previousMedication: [],
            verifiedCheckbox: false,
            decibelData: new CircularArray(200),
            frameNow: 0,
            hasVoice: false,
            recordingTime: 0,
            medicineStatus: [{label: 'Active', value:'active'}, {label: 'Completed', value:'completed'}, {label: 'Entered in Error', value:'entered-in-error'}, {label: 'Intended', value: 'intended'} , {label: 'Stopped', value:'stopped'}, {label: 'Draft', value:'draft'}, {label: 'On Hold', value:'on-hold'}, {label: 'Unknown', value:'unknown'}, {label: 'Not Taken', value:'not-taken'}],
        }
    },
    async created() {
        this.getDataLoading = true
        this.visit_id = this.$route.params.id
        await this.getPatientVisitData()
        await this.startCheckUp()
        this.getDataLoading = false
        // await this.getICD10Codes('')
        // await this.getICD9CMCodes('')
    },
    methods: {
        setActiveButton(buttonKey) {
            this.activeButton = buttonKey;
            if(buttonKey === 5){
                this.openDocument = true
            } else {
                this.openDocument = false
            }
        },
        calculateAge(birthdate) {
            const today = new Date();
            const birthDate = new Date(birthdate);

            const years = today.getFullYear() - birthDate.getFullYear();
            const months = today.getMonth() - birthDate.getMonth();
            const days = today.getDate() - birthDate.getDate();

            let ageYears = years;
            let ageMonths = months;
            let ageDays = days;

            if (ageDays < 0) {
                const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                ageDays += prevMonth.getDate();
                ageMonths -= 1;
            }

            if (ageMonths < 0) {
                ageMonths += 12;
                ageYears -= 1;
            }

            return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`;
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const date = new Date(dateString);
            return date.toLocaleString('id-ID', options);
        },

        formatDateTime(dateString) {
            const date = new Date(dateString);
            const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

            const formattedDate = date.toLocaleDateString('id-ID', dateOptions).split('/').join('-');
            const formattedTime = date.toLocaleTimeString('id-ID', timeOptions).split('.').join(':');

            return `${formattedDate} ${formattedTime}`;
        },

        formatDateTimeFile(dateString) {
            const date = new Date(dateString);
            const dateOptions = { year: 'numeric', month: 'long', day: '2-digit' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

            const formattedDate = date.toLocaleDateString('id-ID', dateOptions).split('/').join('-');
            const formattedTime = date.toLocaleTimeString('id-ID', timeOptions).split('.').join(':');

            return `${formattedDate} ${formattedTime}`;
        },

        async getPatientVisitData(){
            const response = await getPatientVisit(this.visit_id)
            this.patientVisitData = response.data.visit

            const patientResponse = await getById(this.patientVisitData.patient_id)
            this.patientVisitDocument = patientResponse.documents
            this.patientVisitHistoryId = patientResponse.visits

            // console.log(this.patientVisitHistoryId, 'data kunjungan history id')

            this.patientVisitHistory = []
            for (const history in this.patientVisitHistoryId) {
                if (this.patientVisitHistoryId[history].status === 'Selesai'){
                    const res = await getPatientVisit(this.patientVisitHistoryId[history].id)
                    res.data.visit.doctor_name = await this.getUserName(res.data.visit.attending_doctor_id)
                    if(res.data.visit.attending_nurse_id !== null){
                        res.data.visit.nurse_name = await this.getUserName(res.data.visit.attending_nurse_id)
                    } else {
                        res.data.visit.nurse_name = 'Admin'
                    }

                    for (const file in res.data.documents) {
                        res.data.documents[file].created_by_name = await this.getUserName(res.data.documents[file].VisitDocument.created_by)
                        res.data.documents[file].uri = await this.getHistoryDocumentImageUrl(res.data.documents[file], res.data.visit.id)
                    }

                    this.patientVisitHistory.push(res.data)
                }
            }
            // console.log(this.patientVisitHistory.length, 'panjang data kunjungan history')
            // this.patientVisitHistory = this.patientVisitHistory.reverse()
            // console.log(this.patientVisitHistory, 'data kunjungan history')

            this.patientVisitDocument.forEach((file, index) => {
                this.getDocumentImageUrl(file, index)
            })

            await this.documentCreatedByName()

            const perawatName = await this.getUserName(this.patientVisitData.Anamnesis.created_by)

            this.patientVisitData = {
                ...this.patientVisitData,
                age: this.calculateAge(this.patientVisitData.Patient.birth_date),
                birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
                gender: this.patientVisitData.Patient.gender === 'Male' ? 'Laki-laki' : 'Perempuan',
                payment_method: this.patientVisitData.Patient.payment_method === 'JKN' ? 'JKN (BPJS Kesehatan)' : this.patientVisitData.Patient.payment_method,
                anamnesis_created_time: this.formatDateTime(this.patientVisitData.Anamnesis.createdAt),
                anamnesis_created_by: perawatName,
            }

            await this.getPreviousMedication(this.patientVisitData.patient_id)

            // console.log(this.patientVisitData, 'data kunjungan pasien')
            // console.log(this.patientVisitDocument, 'dokumen kunjungan pasien')
        },

        async getPreviousMedication(patientId){
            const doctorId = window.localStorage.getItem('id')
            const response = await getMedicationHistory(patientId, doctorId)
            console.log(response.data.data, 'response data')
            this.previousMedication = response.data.data
        },

        openFile(file, index){
            this.setActiveButton(5)
            this.selectedDocument.file = file
            this.selectedDocument.index = index


        },

        getAllergyType(type){
            if(type === 1){
                return 'Medication'
            } else if(type === 2){
                return 'Food'
            } else if(type === 3){
                return 'Environment'
            } else if(type === 4){
                return 'Biologic'
            }
        },
        async getDocumentImageUrl(file, key) {
            const response = await getDokumenPenunjang(this.patientVisitData.id, file.id)
            // console.log(response, 'response')
            const url = URL.createObjectURL(response.data)
            // console.log(url, 'url')
            // console.log(response, 'response')
            this.photoURL[key] = url
        },

        async getHistoryDocumentImageUrl(file, visitId){
            const response = await getDokumenPenunjang(visitId, file.id)
            const url = URL.createObjectURL(response.data)
            return url
        },

        addPrimaryInput(){
            this.primary_condition.push({ code: '' });
            // console.log(this.primary_condition, 'primary_condition')
        },

        addSecondaryInput() {
            this.secondary_conditions.push({ code: '' });
            // console.log(this.secondary_conditions, 'secondary_conditions')
        },

        addConditions(text){
            // console.log(this.secondary_conditions, 'secondary_conditions')
            if(this.primary_condition.length === 0){
                this.primary_condition.push(text);
            } else {
                this.secondary_conditions.push(text);
            }
            // console.log(this.secondary_conditions, 'secondary_conditions')
        },

        addProceduresInput() {
            this.procedures.push({ code: '' });
        },

        addProcedures(text){
            this.procedures.push(text);
        },

        addMedication() {
            this.medications.push({ kfa: '', name: '', quantity: '', frequency: '', dosage: '', use_morning: false, use_noon: false, use_night: false });
            console.log(this.medications, 'medication list')
        },

        removePrimaryInput(index) {
            this.primary_condition.splice(index, 1);
        },

        // removePrimaryAutoInput(index) {
        //     this.primary_condition_autoGenerated.splice(index, 1);
        // },

        removeSecondaryInput(index) {
            this.secondary_conditions.splice(index, 1);
        },

        // removeSecondaryAutoInput(index) {
        //     this.secondary_conditions_autoGenerated.splice(index, 1);
        // },

        removeProceduresInput(index) {
            this.procedures.splice(index, 1);
        },

        removeMedication(index) {
            this.medications.splice(index, 1);
        },

        // removeProceduresAutoInput(index) {
        //     this.procedures_autoGenerated.splice(index, 1);
        // },

        moveUp(index) {
            if (index > 0) {
                const temp = this.secondary_conditions[index];
                this.secondary_conditions.splice(index, 1);
                this.secondary_conditions.splice(index - 1, 0, temp);
            }
        },

        moveProcedureUp(index) {
            if (index > 0) {
                const temp = this.procedures[index];
                this.procedures.splice(index, 1);
                this.procedures.splice(index - 1, 0, temp);
            }
        },

        moveDown(index) {
            if (index < this.secondary_conditions.length - 1) {
                const temp = this.secondary_conditions[index];
                this.secondary_conditions.splice(index, 1);
                this.secondary_conditions.splice(index + 1, 0, temp);
            }
        },

        moveProcedureDown(index) {
            if (index < this.procedures.length - 1) {
                const temp = this.procedures[index];
                this.procedures.splice(index, 1);
                this.procedures.splice(index + 1, 0, temp);
            }
        },

        beforeUpload(file) {
            file.thumbUrl = URL.createObjectURL(file);
            this.fileList.push(file);
            return false;
        },

        startEdit(index, name) {
            this.editIndex = index;
            this.editName = name; 
        },

        finishEdit(index) {
            if (this.editName) {
                const newFile = new File([this.fileList[index]], this.editName, {
                type: this.fileList[index].type,
                });
                this.fileList[index] = newFile;
                this.fileList[index].thumbUrl = URL.createObjectURL(newFile);
            }
            this.editIndex = null;
            this.editName = '';
        },

        removeFile(index) {
            this.fileList.splice(index, 1);
        },

        uploadFiles() {
            this.isLoading = true
            const formData = new FormData();
            // console.log(this.fileList, 'file list')
            this.fileList.forEach(file => {
                formData.append('documents', file)
            });
            formData.append('visit_id', this.visit_id)

            // console.log(formData, 'form data')

            uploadDokumenPenunjang(formData).then(response => {
                // console.log(response, 'response')
                if (response.status === 200 || response.status === 201) {
                    this.$notification.success({
                        message: 'Berhasil',
                        description: 'Dokumen penunjang berhasil diunggah',
                        duration: 5,
                    })
                }
            }).catch(err => {
                console.log(err)
            })
            this.isLoading = false
        },

        async getUserName(id) {
            try {
                const response = await getUserProfileFromId(id);
                return response.data.user.name;
            } catch (error) {
                console.error('Error fetching user name:', error);
                return 'Unknown';
            }
        },

        async documentCreatedByName() {
            const promises = this.patientVisitDocument.map(async (file, index) => {
                const userName = await this.getUserName(file.created_by);
                this.$set(this.patientVisitDocument, index, {
                ...file,
                created_by_name: userName,
                });
            });
            await Promise.all(promises);
        },

        async startCheckUp(){
            if (this.patientVisitData.status === 'Selesai') {
                this.$notification.warning({
                    message: 'Peringatan',
                    description: 'Pemeriksaan pasien telah selesai',
                    duration: 5,
                })
            } else {
                const payload = {
                    visit_id: this.visit_id,
                }
                const res = await startDoctorCheckUp(payload)
                // console.log(res.data, 'res')
                const responseData = res.data
                if (res.status === 200 || res.status === 201) {
                    this.$notification.success({
                        message: 'Berhasil',
                        description: 'Pemeriksaan pasien telah dimulai',
                        duration: 5,
                    })
                    await this.getPatientVisitData()
                    this.form.setFieldsValue({
                        'subjective': responseData.subjective,
                        'objective': responseData.objective,
                        // 'assessment': responseData.assessment && responseData.assessment.trim() !== ''
                        //             ? responseData.assessment.includes('// Asesmen sebelumnya //') 
                        //                 ? responseData.assessment + "\n ------------------------------------------------------------------------------------ \n"
                        //                 : "// Asesmen sebelumnya // \n\n" + responseData.assessment + "\n ------------------------------------------------------------------------------------ \n"
                        //             : responseData.assessment,
                        'assessment': responseData.assessment,
                        'plan': responseData.plan,
                    })
                }
            }
        },

        handleSubmitSOAP(e) {
            this.submitLoading = true
            if (this.patientVisitData.status === 'Selesai') {
                this.$notification.error({
                    message: 'Gagal',
                    description: 'Tidak dapat menyimpan karena pemeriksaan pasien telah selesai',
                    duration: 5,
                })
                this.submitLoading = false
                return
            }
            e.preventDefault()
            this.form.validateFields((err, values) => {
                if (!err) {
                    // if (this.primary_condition.length === 0 && this.primary_condition_autoGenerated.length === 0) {
                    if (this.primary_condition.length === 0) {
                        this.$notification.error({
                            message: 'Gagal',
                            description: 'Diagnosis primer harus diisi sebelum menyimpan hasil pemeriksaan.',
                            duration: 5,
                        });
                        this.submitLoading = false;
                        return;
                    }
                    values.visit_id = this.visit_id
                    const primaryCondition = this.primary_condition.map(item => {
                        return {
                            ...item,
                            code: item.code.split(' | ')[0].trim(),
                        };
                    });

                    // const primaryConditionAutoGenerated = this.primary_condition_autoGenerated.map(item => {
                    //     return {
                    //         ...item,
                    //         code: item.code.split(' | ')[0].trim(),
                    //     };
                    // });

                    // values.primary_condition = primaryCondition[0] || primaryConditionAutoGenerated[0];
                    values.primary_condition = primaryCondition[0]

                    const secondaryConditions = this.secondary_conditions.map(item => {
                        return {
                            ...item,
                            code: item.code.split(' | ')[0].trim(),
                        };
                    });
                    
                    // const secondaryConditionsAutoGenerated = this.secondary_conditions_autoGenerated.map(item => {
                    //     return {
                    //         ...item,
                    //         code: item.code.split(' | ')[0].trim(),
                    //     };
                    // });

                    // if (secondaryConditions.length > 0) {
                    //     values.secondary_conditions = secondaryConditionsAutoGenerated;
                    //     values.secondary_conditions = values.secondary_conditions.concat(secondaryConditions)
                    // } else {
                    //     values.secondary_conditions = secondaryConditionsAutoGenerated;
                    // }
                    values.secondary_conditions = secondaryConditions


                    const procedures = this.procedures.map(item => {
                        return {
                            ...item,
                            code: item.code.split(' | ')[0].trim(),
                            status: "preparation",
                        };
                    });

                    // const proceduresAutoGenerated = this.procedures_autoGenerated.map(item => {
                    //     return {
                    //         ...item,
                    //         code: item.code.split(' | ')[0].trim(),
                    //         status: "preparation",
                    //     };
                    // });

                    // if (procedures.length > 0) {
                    //     values.procedures = proceduresAutoGenerated;
                    //     values.procedures = values.procedures.concat(procedures)
                    // } else {
                    //     values.procedures = proceduresAutoGenerated;
                    // }
                    values.procedures = procedures

                    values.transcript = this.transcript

                    values.medications = this.medications

                    console.log('Received values of form: ', values)
                    saveDoctorNote(values).then(async response => {
                        // console.log(response, 'response')
                        if (response.status === 200 || response.status === 201) {
                            this.$notification.success({
                                message: 'Berhasil',
                                description: 'Catatan SOAP berhasil disimpan',
                                duration: 5,
                            })
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            await this.getPatientVisitData()
                            this.submitLoading = false
                            this.$router.push('/pemeriksaan/dokter')
                        }
                    }).catch(err => {
                        console.log(values, 'value error')
                        console.log(err, 'error')
                        this.$notification.error({
                            message: 'Gagal',
                            description: `Terjadi kesalahan saat menyimpan catatan SOAP, ${err}`,
                            duration: 5,
                        })
                        this.submitLoading = false
                    })
                }
            });
            
        },

        openRecordingForm() {
            this.isStartRecording = true
            this.startRecording()
        },

        // old version

        // async startRecording() {
        //     this.isRecording = true;
        //     console.log('Recording Started')

        //     this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //     this.recorder = new MediaRecorder(this.mediaStream)
        //     this.recorder.ondataavailable = event => {
        //         this.audioChunks.push(event.data)
        //         console.log('event.data:', event.data)
        //     }

        //     this.recorder.onstop = async () => {
        //         const audioBlob = new Blob(this.audioChunks, { 'type': 'audio/x-m4a' });
        //         if (audioBlob.size > 0) {
        //             const url = URL.createObjectURL(audioBlob);
        //             const a = document.createElement('a');
        //             a.style.display = 'none';
        //             a.href = url;
        //             a.download = 'recording.m4a';
        //             document.body.appendChild(a);
        //             a.click();
        //             document.body.removeChild(a);
        //             URL.revokeObjectURL(url);
        //             // await this.sendAudio(audioBlob);
        //         }
        //         this.audioChunks = [];
        //         if (this.isRecording) {
        //             this.recorder.start();
        //         }
        //     };

        //     this.recorder.start()

        //     const intervalId = setInterval(async () => {
        //         if (!this.isRecording) {
        //             clearInterval(intervalId);
        //         } else {
        //             this.recorder.stop();
        //             // this.recorder.start();
        //             // const audioBlob = new Blob(this.audioChunks, { 'type' : 'audio/x-m4a' });
        //             // if (audioBlob.size > 0) {
        //             //     const url = URL.createObjectURL(audioBlob);
        //             //     const a = document.createElement('a');
        //             //     a.style.display = 'none';
        //             //     a.href = url;
        //             //     a.download = 'recording.m4a';
        //             //     document.body.appendChild(a);
        //             //     a.click();
        //             //     document.body.removeChild(a);
        //             //     URL.revokeObjectURL(url);
        //             //     await this.sendAudio(audioBlob);
        //             // }
        //             // this.audioChunks.length = 0;
        //         }
        //     }, 10000); // 10 Detik

        async startRecording() {
            this.isRecording = true;
            console.log('Recording Started');

            this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.analyser = this.audioContext.createAnalyser();
            this.source = this.audioContext.createMediaStreamSource(this.mediaStream);
            this.source.connect(this.analyser);
            this.analyser.fftSize = 256;
            this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

            this.recorder = new MediaRecorder(this.mediaStream);
            this.audioChunks = [];

            this.recorder.ondataavailable = event => {
                this.audioChunks.push(event.data);
                console.log('event.data:', event.data);
            };

            this.recorder.onstop = async () => {
                const audioBlob = new Blob(this.audioChunks, { 'type': 'audio/x-m4a' });
                if (audioBlob.size > 0) {
                    // const url = URL.createObjectURL(audioBlob);
                    // const a = document.createElement('a');
                    // a.style.display = 'none';
                    // a.href = url;
                    // a.download = `recording_${Date.now()}.m4a`;
                    // document.body.appendChild(a);
                    // a.click();
                    // document.body.removeChild(a);
                    // URL.revokeObjectURL(url);
                    this.sendAudio(audioBlob)
                }
                this.audioChunks = [];
                if (this.isRecording) {
                    this.recorder.start();
                    if (this.isRecording) {
                        setTimeout(() => {
                            this.startNoiseDetection();
                        }, 0); // Tunggu 5 detik
                    }
                }
                
            };

            this.recorder.start();
            if (this.isRecording) {
                setTimeout(() => {
                    this.startNoiseDetection();
                }, 0); // Tunggu 5 detik
            }
        },

        getVoiceLimit() {
            const begin = this.decibelData.Smallest;
            const end = this.decibelData.Largest;
            const diff = end - begin;
            const limit1 = begin + diff * DecibleVadConfig.noSpeechPercentile + DecibleVadConfig.noSpeechOffset;
            const limit2 = begin + diff * DecibleVadConfig.speechPercentile + DecibleVadConfig.speechOffset;
            return [limit1, limit2];
        },

        startNoiseDetection() {
            const intervalId = setInterval(async () => {
                if (!this.isRecording) {
                    clearInterval(intervalId);
                } else {
                    this.analyser.getByteFrequencyData(this.dataArray);
                    const rms = Math.sqrt(this.dataArray.reduce((sum, value) => sum + value * value, 0) / this.dataArray.length);
                    const decibels = 20 * Math.log10(rms);

                    this.decibelData.add(decibels);
                    // console.log({
                    //     decibels,
                    //     noSpeechLimit: this.getVoiceLimit()[0],
                    //     speechLimit: this.getVoiceLimit()[1],
                    //     smallest: this.decibelData.Smallest,
                    //     largest: this.decibelData.Largest,
                    // });

                    if (decibels > this.getVoiceLimit()[1]) {
                        this.hasVoice = true;
                    }
                    this.frameNow++;

                    if (decibels < this.getVoiceLimit()[0] && 
                        this.frameNow * DecibleVadConfig.detectInterval > DecibleVadConfig.startDetect &&
                        (this.hasVoice || this.frameNow * DecibleVadConfig.detectInterval > DecibleVadConfig.maxAudioLength)
                    ) { // dynamic threshold
                        this.frameNow = 0;
                        this.hasVoice = false;
                        this.recorder.stop();
                        clearInterval(intervalId);
                    }
                }
            }, DecibleVadConfig.detectInterval); // 100ms
        },

        // gabisa wak ternyata grgr kalau chunk 2-dst itu gaada metadata-nya jd gabisa dibuat "realtime"
        // async startRecording() {
        //     this.isRecording = true;
        //     console.log('Recording Started');

        //     this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //     this.recorder = new MediaRecorder(this.mediaStream);
        //     this.audioChunks = []; // Initialize audioChunks before starting recording

        //     this.recorder.ondataavailable = async event => {
        //         if (event.data.size > 0) {
        //             this.audioChunks.push(event.data);
        //             console.log('audioChunks:', this.audioChunks);
        //             console.log('event.data:', event.data);

        //             await this.downloadAudio();

                    
        //         }
        //     };

        //     this.recorder.start(10000); // timeslice in milliseconds (10 seconds)
        // },

        // async downloadAudio(){
        //     const audioBlob = new Blob(this.audioChunks, { 'type': 'audio/x-m4a' });
        //     console.log('audioBlob:', audioBlob);
        //     const url = URL.createObjectURL(audioBlob);
        //     console.log('url:', url);
        //     const a = document.createElement('a');
        //     a.style.display = 'none';
        //     a.href = url;
        //     a.download = `recording_${Date.now()}.m4a`; // Use timestamp to avoid overwriting
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //     URL.revokeObjectURL(url);

        //     this.audioChunks = []; // Clear the chunks after saving
        // },

        async stopRecording() {
            this.isRecording = false;
            this.recorder.stop();

            if (this.mediaStream) {
                this.mediaStream.getTracks().forEach(track => 
                // {
                //     console.log(`Stopping track: ${track.kind}`);
                //     track.stop();
                // },
                track.stop(),
            );
                this.mediaStream = null;
            }

            if (this.audioChunks.length > 0) {
                const audioBlob = new Blob(this.audioChunks, { 'type' : 'audio/x-m4a' });
                if (audioBlob.size > 0) {
                    // const url = URL.createObjectURL(audioBlob);
                    // const a = document.createElement('a');
                    // a.style.display = 'none';
                    // a.href = url;
                    // a.download = 'recording.m4a';
                    // document.body.appendChild(a);
                    // a.click();
                    // document.body.removeChild(a);
                    // URL.revokeObjectURL(url);

                    await this.sendAudio(audioBlob);

                }
            }
            this.audioSegments = [];
            console.log('Recording Stopped');
        },

        async sendAudio(audioBlob) {
            this.audioSegments.push(audioBlob);
            console.log('Recording Segments:', this.audioSegments);
            console.log('Recording Segments Length:', this.audioSegments.length);

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const arrayBuffer = await audioBlob.arrayBuffer();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            const audioDuration = audioBuffer.duration;

            if (this.audioSegments.length > 0) {
                const formData = new FormData()
                formData.append('model', 'large-v2')
                formData.append('note_id', this.visit_id)
                console.log(this.recordingTime, 'recording time')
                formData.append('offset_ms', this.recordingTime * 1000)
                formData.append('audiofile', audioBlob, `segment-${this.audioSegments.length}.m4a`)
                console.log('Form Data:', formData)

                this.recordingTime = this.recordingTime + audioDuration
                // this.$notification.success({
                //     message: 'Mengirim',
                //     description: 'Mengirim audio ' + this.audioSegments.length + ' ke server',
                //     duration: 5,
                // })

                const response = await addAudio(formData)
                console.log(`Response Audio ke-${response.data.order}:`, response)
                const transcript = response.data.transcript
                if (transcript) {
                    this.transcript += transcript
                    this.transcript += '\n \n'
                }
            }
        },

        async getICD10Codes(search) {
            const ICD10response = await searchICD10Code(search)
            this.ICD10List = ICD10response.data.codes
            // console.log(this.ICD10List, 'ICD10 List')
        },

        async getICD9CMCodes(search) {
            const ICD9CMresponse = await searchICD9CMCode(search)
            this.ICD9List = ICD9CMresponse.data.codes
            // console.log(this.ICD9List, 'ICD9CM List')
        },

        async getMedicine(search){
            const response = await getMedications(search)
            this.medicineList = response.data.data
            // console.log(this.medicineList, 'medicine list')
        },

        async searchICD10(query) {
            if (query) {
                try {
                // console.log('Searching ICD10 codes:', query)
                const response = await searchICD10Code(query)
                this.ICD10List = response.data.codes
                // console.log('ICD10 codes:', this.ICD10List)
                } catch (error) {
                console.error('Error fetching ICD10 codes:', error)
                this.ICD10List = []
                }
            } else {
                this.ICD10List = []
            }
        },

        async searchICD9CM(query) {
            if (query) {
                try {
                // console.log('Searching ICD9CM codes:', query)
                const response = await searchICD9CMCode(query)
                this.ICD9CMList = response.data.codes
                // console.log('ICD9CM codes:', this.ICD9CMList)
                } catch (error) {
                console.error('Error fetching ICD9CM codes:', error)
                this.ICD9CMList = []
                }
            } else {
                this.ICD9CMList = []
            }
        },

        async searchMedicine(query) {
            if (query) {
                try {
                // console.log('Searching medications:', query)
                const response = await getMedications(query)
                this.medicineList = response.data.data
                // console.log('Medications:', this.medicineList)
                } catch (error) {
                console.error('Error fetching medications:', error)
                this.medicineList = []
                }
            } else {
                this.medicineList = []
            }
        },

        selectMedicine(index, medicine) {
            const selectedMedicine = this.medicineList.find(item => item.name === medicine);
            this.medications[index].kfa = selectedMedicine.id;
            this.medications[index].name = selectedMedicine.name;
            console.log(this.medications, 'medications list')
        },

        async generateICD10() {
            this.ICD10Loading = true;
            const assessment = this.form.getFieldValue('assessment');
            const payload = {
                "text": assessment,
            }
            const res = await generateICD10Code(payload)

            const data = res.data.codes
            if (data.length > 0) {
                const formattedData = data.map(item => ({
                    code: item.skri + " | " + item.STR,
                }));
                // this.primary_condition = []
                // this.primary_condition_autoGenerated = []
                // this.primary_condition_autoGenerated = formattedData.slice(0, 1)

                if (formattedData.length > 0){
                    this.conditions_autoGenerated = formattedData
                }

                // console.log(this.primary_condition_autoGenerated, 'primary_condition_autoGenerated')
                // console.log(this.secondary_conditions_autoGenerated, 'secondary_conditions_autoGenerated')
            } else {
                this.$notification.warning({
                    message: 'Peringatan',
                    description: 'Tidak ada kode ICD-10 yang dihasilkan',
                    duration: 5,
                })
            }
            this.ICD10Loading = false;
        },

        async generateICD9CM() {
            this.ICD9CMLoading = true;
            try{
                const plan = this.form.getFieldValue('plan');
                const payload = {
                    "text": plan,
                }
                const res = await generateICD9CMCode(payload)
                const data = res.data.codes
                if (data.length > 0) {
                    const formattedData = data.map(item => ({
                        code: item.CODE + " | " + item.STR,
                    }));
                    this.procedures_autoGenerated = formattedData
                } else {
                    this.$notification.warning({
                        message: 'Peringatan',
                        description: 'Tidak ada kode ICD-9-CM yang dihasilkan',
                        duration: 5,
                    })
                }
            } catch (error){
                console.error('Error generating ICD-9-CM:', error)
                this.$notification.error({
                    message: 'Gagal',
                    description: 'Terjadi kesalahan saat menghasilkan kode ICD-9-CM',
                    duration: 5,
                })
            }
            this.ICD9CMLoading = false;

        },

        async generateSOAPFromTranscript(){
            const subjective = this.form.getFieldValue('subjective')
            // console.log('Subjective:', subjective)
            const objective = this.form.getFieldValue('objective')
            const assessment = this.form.getFieldValue('assessment')
            const plan = this.form.getFieldValue('plan')
            this.generateSOAPLoading = true
            const payload = {
                transcript: this.transcript,
                visit_id: this.visit_id,
                subjective: subjective,
                objective: objective,
                assessment: assessment,
                plan: plan,
            }

            try{
                const res = await generateSOAP(payload)
                const soap = res.data.SOAP
                const icd10 = res.data.icd10
                const icd9cm = res.data.icd9cm
                console.log('SOAP:', soap)
                console.log('ICD10:', icd10)
                console.log('ICD9CM:', icd9cm)
                this.assessmentRecommendation = soap.ai_assessment
                this.planRecommendation = soap.ai_plan
    
                this.generateSOAPLoading = false
                if (subjective === null || subjective === undefined || subjective === ''){
                    this.form.setFieldsValue({
                        'subjective': soap.subjective,
                    })
                } else {
                    this.form.setFieldsValue({
                        'subjective': subjective + '\n\n' + soap.subjective,
                    })
                }

                this.form.setFieldsValue({
                    'objective': soap.objective,
                })
                
                if (assessment === null || assessment === undefined || assessment === ''){
                    this.form.setFieldsValue({
                        'assessment': soap.assessment,
                    })
                } else {
                    this.form.setFieldsValue({
                        'assessment': assessment + '\n\n' + soap.assessment,
                    })
                }

                if (plan === null || plan === undefined || plan === ''){
                    this.form.setFieldsValue({
                        'plan': soap.plan,
                    })
                } else {
                    this.form.setFieldsValue({
                        'plan': plan + '\n\n' + soap.plan,
                    })
                }
    
                if (icd10.length > 0) {
                    const formattedICD10 = icd10.map(item => ({
                        code: item.skri + " | " + item.STR,
                    }));
                    // this.primary_condition = []
                    // this.primary_condition_autoGenerated = []
                    // this.primary_condition_autoGenerated = formattedICD10.slice(0, 1)
    
                    if (formattedICD10.length > 0){
                        // this.secondary_conditions_autoGenerated = formattedICD10.slice(1)
                        this.conditions_autoGenerated = formattedICD10
                    }
                }
    
                if (icd9cm.length > 0) {
                    const formattedICD9CM = icd9cm.map(item => ({
                        code: item.CODE + " | " + item.STR,
                    }));
                    this.procedures_autoGenerated = formattedICD9CM
                }

                this.$notification.success({
                    message: 'Berhasil ',
                    description: 'SOAP berhasil dibuat dengan ' + icd10.length + ' kode ICD-10 dan ' + icd9cm.length + ' kode ICD-9-CM',
                    duration: 5,
                })

            } catch (error){
                console.error('Error generating SOAP:', error)
                this.$notification.error({
                    message: 'Gagal',
                    description: `Terjadi kesalahan saat menghasilkan SOAP dari transkripsi: ${error}`,
                    duration: 5,
                })
                this.generateSOAPLoading = false
            }

            this.generateSOAPLoading = false

        },
        openModal(){
            this.form.validateFields((err, values) => {
                if (!err) {
                    if(this.primary_condition.length === 0){
                        this.$notification.warning({
                            message: 'Peringatan',
                            description: 'Diagnosis primer harus diisi sebelum menyimpan hasil pemeriksaan.',
                            duration: 5,
                        })
                        return
                    }
                    if(this.medications.some(medication => medication.name === '' || medication.quantity === '' || medication.frequency === '' || medication.dosage === '')){
                        this.$notification.warning({
                            message: 'Peringatan',
                            description: 'Resep tidak boleh kosong atau memiliki komponen yang kosong.',
                            duration: 5,
                        })
                        return
                    }
                    this.verifiedCheckbox = false
                    this.showModal = true
                    this.subjective = values.subjective
                    this.objective = values.objective
                    this.assessment = values.assessment
                    this.plan = values.plan
                    this.medication_note = values.medication_note
                }
            })
        },
        openPrevMedicationModal(){
            this.showMedicationModal = true
        },
        openrecommendationAssessmentModal(){
            this.recommendationAssessmentModal = !this.recommendationAssessmentModal
        },
        openrecommendationPlanModal(){
            this.recommendationPlanModal = !this.recommendationPlanModal
        },
        copyAssessment(){
            const assessment = this.form.getFieldValue('assessment')
            if (assessment === null || assessment === undefined || assessment === ''){
                this.form.setFieldsValue({
                    'assessment': this.assessmentRecommendation,
                })
            } else {
                this.form.setFieldsValue({
                    'assessment': assessment + '\n\n' + this.assessmentRecommendation,
                })
            }
            this.copyToClipboard(this.assessmentRecommendation)
        },
        copyPlan(){
            const plan = this.form.getFieldValue('plan')
            if (plan === null || plan === undefined || plan === ''){
                this.form.setFieldsValue({
                    'plan': this.planRecommendation,
                })
            } else {
                this.form.setFieldsValue({
                    'plan': plan + '\n\n' + this.planRecommendation,
                })
            }
            this.copyToClipboard(this.planRecommendation)
        },
        copyToClipboard(text) {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text).then(() => {
                }).catch(err => {
                console.error('Terjadi error saat menyalin teks: ', err);
                });
            } else {
                const textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);
                textarea.select();
                try {
                document.execCommand('copy');
                } catch (err) {
                console.error('Terjadi error saat menyalin teks: ', err);
                }
                document.body.removeChild(textarea);
            }
        },
        formatText(text) {
            return text.replace(/\n/g, '<br>');
        },
        onCheckboxChange(e) {
            this.verifiedCheckbox = e.target.checked
        },
        copyMedication(medicationRequest){
            medicationRequest.forEach(medication => {
                this.medications.push({
                    kfa: medication.kfa,
                    name: medication.name,
                    quantity: medication.quantity,
                    frequency: medication.frequency,
                    dosage: medication.dosage,
                    use_morning: medication.use_morning,
                    use_noon: medication.use_noon,
                    use_night: medication.use_night,
                })
            })
            this.showMedicationModal = false
        },
    },
}

</script>

<style scoped>
.floating-loading {
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    @media (max-width: 768px) {
        top: 40px;
    }
}
.text-color-green {
    color: #428A94;
}
.background-blue {
    background-color: #F6FEFF;
}

button {
  background-color: white;
  color: #428A94;
  padding: 10px;
  border: 1px solid #428A94;
  border-radius: 26px;
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
  height: 40px;
  padding: 8px 16px;
}

button.active {
  background-color: #428A94;
  color: white;
}

.content-div {
  margin-top: 20px;
  padding: 24px;
  border: 1px solid #ccc;
  border-radius: 16px;
  /* min-width: 35vw; */
}

.content-div-no-border {
  margin-top: 20px;
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  background-color: #F6FEFF;
  /* min-width: 35vw; */
}

.file-list {
    list-style-type: none;
    margin-left:-40px;
    margin-top: 10px;
}
.list-border {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    width: 100%;
    height: 68px;
}

/* Button */
.buttons-container {
    display: flex;
    justify-content: flex-end;

}
.buttons-container .button {
    margin: 10px;
    border-radius: 20px;
    padding: 5px 10px;
    
}

.stop-button {
    background-color: #DCF9FF;
    border: 1px solid #C5F1FF;
    color: #428A94;
}

.file-list {
    list-style-type: none;
    margin-left:-40px;
    margin-top: 10px;
}
.list-border {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    width: 100%;
    height: 68px;
}
.edit-file-input {
    min-width: 600px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
}

.tag {
    background-color: #E3FFF8;
    color: #15B79E;
    border-radius: 999px;
    padding: 2px, 8px, 2px, 8px;
    text-align: center;
    width: 92px;
    height: 24px;
    align-items: center;
}

.loading-container{
    display: grid; 
    justify-content: center; 
    align-items: center; 
    height: 40vh;
}

.loading-content{
    text-align: center; 
    margin-top: 1rem
}

.recommendation-button-container{
    display: flex;
    flex-wrap: wrap;
    gap: 8px; 
}

.SOAP-title-color {
    color: #428A94;
}

.flex-container {
    flex-wrap: wrap; 
    max-width: 100%;
}
</style>