/**
 * Supports max/min retrieval
 * 
 */
class CircularArray {
    
    constructor(maxCount) {
        this.maxCount = maxCount;
        this.array = [];
        this.idx = 0;
        
        this.sortedArray = [];
        this.dirty = true;
    }

    add(value) {
        this.array[this.idx] = value;
        this.idx = (this.idx + 1) % this.maxCount;
        this.dirty = true;
    }

    rebuildSortedArray() {
        if (this.dirty) {
            this.sortedArray = [...this.array];
            this.sortedArray.sort();
            this.dirty = false;
        }
        return this.sortedArray;
    }

    get Largest() {
        this.rebuildSortedArray();
        return this.sortedArray[Math.min(this.sortedArray.length - Math.floor(this.sortedArray.length * 0.05), this.sortedArray.length - 1)];
    }
    
    get Smallest() {
        this.rebuildSortedArray();
        return this.sortedArray[Math.max(Math.floor(this.sortedArray.length * 0.05) - 1, 0)];
    }
}

export default CircularArray;